var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticStyle:{"max-width":"800px"},attrs:{"flat":""}},[_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.felter,"items":_vm.treff,"item-key":"treffId","no-data-text":"Ingen besøk.","expanded":_vm.ekspandert,"items-per-page":-1,"single-expand":false,"hide-default-footer":"","must-sort":""},on:{"update:expanded":function($event){_vm.ekspandert=$event}},scopedSlots:_vm._u([{key:"header.rediger",fn:function(){return [_c('v-btn',{attrs:{"fab":"","small":"","color":"primary"},on:{"click":function($event){return _vm.add()}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)]},proxy:true},{key:"item.date",fn:function(ref){
var item = ref.item;
return [(item.aktivitet)?[_c('span',{domProps:{"innerHTML":_vm._s(item.aktivitet.start.format('DD.MM.YYYY HH.mm'))}}),_vm._v("  "),(item.aktivitet.slutt)?_c('span',{domProps:{"innerHTML":_vm._s(' - ' + item.aktivitet.slutt.format('HH.mm'))}}):_vm._e()]:(!item.rediger)?void 0:_vm._e()]}},{key:"item.oppsummering",fn:function(ref){
var item = ref.item;
return [_c('div',{class:{ 'py-2': !item.clamp, 'v-data-col--clamp': item.clamp },domProps:{"innerHTML":_vm._s(_vm.nl2br(item.oppsummering))},on:{"click":function($event){item.clamp = !item.clamp}}})]}},{key:"item.rediger",fn:function(ref){
var item = ref.item;
return [(item.treffId && !item.rediger)?_c('div',{staticClass:"d-flex justify-center"},[_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.edit(item)}}},[_vm._v("mdi-pencil")])],1):_vm._e()]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-6",staticStyle:{"height":"auto","background-color":"#eee"},attrs:{"colspan":_vm.felter.length}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('date-field',{staticClass:"mr-4",attrs:{"rules":_vm.rules.dato,"label":"Dag"},model:{value:(item.dag),callback:function ($$v) {_vm.$set(item, "dag", $$v)},expression:"item.dag"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('time-field',{attrs:{"rules":_vm.rules.start,"label":"Fra"},model:{value:(item.start),callback:function ($$v) {_vm.$set(item, "start", $$v)},expression:"item.start"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('time-field',{attrs:{"rules":_vm.rules.slutt,"label":"Til"},model:{value:(item.slutt),callback:function ($$v) {_vm.$set(item, "slutt", $$v)},expression:"item.slutt"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"rows":"2","auto-grow":"","label":"Oppsummering"},model:{value:(item.nyOppsummering),callback:function ($$v) {_vm.$set(item, "nyOppsummering", $$v)},expression:"item.nyOppsummering"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"mr-4",attrs:{"color":"primary"},on:{"click":function($event){return _vm.save(item)}}},[_vm._v(" "+_vm._s(item.treffId ? 'Lagre' : 'Opprett')+" ")]),(item.treffId)?_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.remove(item)}}},[_vm._v(" Slett")]):_vm._e(),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.cancel(item)}}},[_vm._v(" Lukk")])],1)],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }