<template>
    <v-card flat style="max-width: 800px">
        <v-card-text>
            <v-data-table
                :headers="felter"
                :items="treff"
                item-key="treffId"
                no-data-text="Ingen besøk."
                :expanded.sync="ekspandert"
                :items-per-page="-1"
                :single-expand="false"
                hide-default-footer
                must-sort
            >
                <template v-slot:header.rediger>
                    <v-btn v-on:click="add()" fab small color="primary">
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </template>

                <template v-slot:item.date="{ item }">
                    <template v-if="item.aktivitet">
                        <span v-html="item.aktivitet.start.format('DD.MM.YYYY HH.mm')"></span>&nbsp;
                        <span v-if="item.aktivitet.slutt" v-html="' - ' + item.aktivitet.slutt.format('HH.mm')"></span>
                    </template>
                    <template v-else-if="!item.rediger">
                        <!--<v-btn v-on:click="edit(item)" outlined>Nytt besøk</v-btn>-->
                    </template>
                </template>

                <template v-slot:item.oppsummering="{ item }">
                    <div
                        v-html="nl2br(item.oppsummering)"
                        v-bind:class="{ 'py-2': !item.clamp, 'v-data-col--clamp': item.clamp }"
                        v-on:click="item.clamp = !item.clamp"
                    ></div>
                </template>

                <template v-slot:item.rediger="{ item }">
                    <div v-if="item.treffId && !item.rediger" class="d-flex justify-center">
                        <v-icon small v-on:click="edit(item)">mdi-pencil</v-icon>
                    </div>
                </template>

                <template v-slot:expanded-item="{ item }">
                    <td :colspan="felter.length" class="py-6" style="height: auto; background-color: #eee">
                        <v-form ref="form" v-model="valid" lazy-validation>
                            <v-row>
                                <v-col cols="4">
                                    <date-field v-model="item.dag" :rules="rules.dato" label="Dag" class="mr-4"></date-field>
                                </v-col>
                                <v-col cols="4">
                                    <time-field v-model="item.start" :rules="rules.start" label="Fra"></time-field>
                                </v-col>
                                <v-col cols="4">
                                    <time-field v-model="item.slutt" :rules="rules.slutt" label="Til"></time-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-textarea v-model="item.nyOppsummering" rows="2" auto-grow label="Oppsummering"> </v-textarea>
                                </v-col>
                                <v-col cols="12">
                                    <v-btn color="primary" class="mr-4" v-on:click="save(item)"> {{ item.treffId ? 'Lagre' : 'Opprett' }} </v-btn>
                                    <v-btn v-if="item.treffId" text v-on:click="remove(item)"> Slett</v-btn>
                                    <v-btn text v-on:click="cancel(item)"> Lukk</v-btn>
                                </v-col>
                            </v-row>
                        </v-form>
                    </td>
                </template>
            </v-data-table>
        </v-card-text>
    </v-card>
</template>

<script>
import moment from 'moment';
import { mapActions } from 'vuex';
import DateField from '@/vendor/noop/components/DateField.vue';
import TimeField from '@/vendor/noop/components/TimeField.vue';

export default {
    name: 'TabVisits',
    components: { DateField, TimeField },
    props: {
        value: {
            type: Object,
        },
    },
    data() {
        return {
            hjemmetjeneste: {},

            treff: [],
            ekspandert: [],

            valid: true,
            form: {
                dato: null,
                start: '00:00',
                slutt: '00:00',
            },

            felter: [
                { text: 'Tidspunkt', value: 'date', sortable: false, width: '240px' },
                { text: 'Oppsummering', value: 'oppsummering', sortable: false },
                { text: '', value: 'rediger', sortable: false, width: '40px' },
            ],

            rules: {
                dato: [(v) => !!v || 'Må oppgis'],
                start: [(v) => !!v || 'Må oppgis'],
            },
        };
    },
    filters: {
        format: function (value, format) {
            return value.format(format);
        },
    },

    /**
     * created
     */
    created: async function () {
        this.hjemmetjeneste = this.value;

        await this.update();
    },

    methods: {
        ...mapActions('api', ['request', 'formatVisit', 'formatActivity', 'formatPerson']),
        ...mapActions(['snackbar', 'confirm']),

        /**
         * update
         */
        update: async function (hjemmetjeneste) {
            if (hjemmetjeneste) {
                this.hjemmetjeneste = await this.formatVisit(hjemmetjeneste);
                this.$emit('input', this.hjemmetjeneste);
            }

            this.treff = [];
            this.ekspandert = [];

            if (this.hjemmetjeneste && this.hjemmetjeneste.treff) {


                console.log(this.hjemmetjeneste.treff);

                // TODO: filter etter kobling/bruker?
                const aktiviteter = await this.request({
                    method: 'get',
                    url: '/aktivitet/aktivitet-type/HJEMMETJENESTE',
                });

                if (aktiviteter) {
                    for (const aktivitet of aktiviteter) {
                        for (let treff of this.hjemmetjeneste.treff) {
                            treff = Object.assign({}, treff);

                            if (treff.treffId == aktivitet.id) {
                                treff.aktivitet = await this.formatActivity(aktivitet);
                                treff.dag = moment(treff.aktivitet.start);
                                treff.start = treff.aktivitet.start ? treff.aktivitet.start.format('HH:mm') : '';
                                treff.slutt = treff.aktivitet.slutt ? treff.aktivitet.slutt.format('HH:mm') : '';
                                if (!treff.oppsummering) {
                                    treff.oppsummering = '';
                                }
                                treff.nyOppsummering = treff.oppsummering;
                                treff.rediger = false;
                                treff.clamp = true;
                                this.treff.push(treff);
                                break;
                            }
                        }
                    }
                }
            }
        },

        /**
         * add
         */
        add: function () {
            const added = this.treff.filter((e) => {
                return !e.treffId;
            });
            if (!added.length) {
                const item = {
                    treffId: '',
                    dag: moment(),
                    start: '',
                    slutt: '',
                    aktivitet: null,
                    rediger: true,
                };
                this.treff.push(item);
                this.ekspandert.push(item);
            }
        },

        /**
         * save
         */
        save: async function (item) {
            const data = new FormData();
            data.append('start', item.dag.format('DD.MM.YYYY') + ' ' + item.start);
            data.append('slutt', item.dag.format('DD.MM.YYYY') + ' ' + item.slutt);
            data.append('oppsummering', item.nyOppsummering);

            const response = await this.request({
                method: 'post',
                url: '/hjemmetjeneste/' + this.hjemmetjeneste.id + '/treff' + (item.treffId ? '/' + item.treffId : ''),
                data,
            });

            if (!response) {
                console.log('TODO: error');
            } else {
                this.update(response);
                this.snackbar('Opprettet');
            }
        },

        /**
         * remove
         */
        remove: async function (item) {
            if (await this.confirm({ title: 'Slette?', message: 'Sikker på at du vil slette besøket?' })) {
                const response = await this.request({
                    method: 'delete',
                    url: '/hjemmetjeneste/' + this.hjemmetjeneste.id + '/treff' + (item.treffId ? '/' + item.treffId : ''),
                });
                if (!response) {
                    console.log('TODO: error');
                } else {
                    this.update(response);
                    this.snackbar('Slettet');
                }
            }
        },

        /**
         * edit
         */
        edit: async function (item) {
            item.rediger = true;
            this.ekspandert.push(item);
        },

        /**
         * cancel
         */
        cancel: function (item) {
            item.rediger = false;
            this.ekspandert = this.ekspandert.filter((e) => {
                return e !== item;
            });

            if (!item.treffId) {
                this.treff = this.treff.filter((e) => {
                    return e !== item;
                });
            }
        },

        /**
         * nl2br
         */
        nl2br: function (str) {
            return str ? str.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br>') : '';
        },
    },
};
</script>
