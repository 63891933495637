<template>
    <v-container fluid v-if="hjemmetjeneste">
        <v-row>
            <v-col cols="12" class="d-flex align-center justify-space-between">
                <h1 class="text-h4" style="flex-grow: 1">{{ hjemmetjeneste.bruker.arkivert ? '(Bruker slettet)' : hjemmetjeneste.bruker.fulltNavn }}</h1>
            </v-col>
            <v-col cols="12">
                <v-tabs v-model="tab" class="v-tabs--content">
                    <v-tab>Generelt</v-tab>
                    <v-tab>Besøk {{ antallTreff }}</v-tab>
                </v-tabs>
                <br />
                <v-tabs-items v-model="tab">
                    <v-tab-item>
                        <v-card flat style="max-width: 800px">
                            <v-card-text>
                                <v-form ref="form" v-model="valid" lazy-validation>
                                    <v-row>
                                        <v-col cols="4">
                                            <v-text-field v-model="hjemmetjeneste.type.navn" label="Tjeneste" readonly disabled> </v-text-field>
                                        </v-col>
                                        <v-col cols="4">
                                            <date-field v-model="hjemmetjeneste.opprettet" label="Opprettet" readonly disabled></date-field
                                        ></v-col>
                                        <v-col cols="4"> <date-field v-model="form.avsluttet" label="Avsluttet"></date-field></v-col>
                                        <v-col cols="12">
                                            <div v-if="hjemmetjeneste.frivillig.arkivert">(Frivillig slettet)</div>
                                            <v-text-field v-else v-model="hjemmetjeneste.frivillig.fulltNavn" label="Frivillig" readonly disabled>
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-textarea v-model="form.beskrivelse" label="Beskrivelse" rows="2" auto-grow></v-textarea>
                                        </v-col>
                                    </v-row>
                                </v-form>
                                <br />
                            </v-card-text>
                            <v-card-actions>
                                <v-btn v-on:click="save" outlined>Lagre</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item>
                        <tab-meetings v-model="hjemmetjeneste"></tab-meetings>
                    </v-tab-item>
                </v-tabs-items>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import DateField from '@/vendor/noop/components/DateField';
import TabMeetings from '@/pages/visit/TabMeetings';

export default {
    name: 'Visit',
    components: {
        DateField,
        TabMeetings,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            valid: null,
            hjemmetjeneste: null,

            form: {
                avsluttet: null,
                beskrivelse: '',
            },

            tab: null,
        };
    },
    computed: {
        ...mapGetters('api', ['hasUserRole']),
        antallTreff: function () {
            let antall = 0;
            if (this.hjemmetjeneste) {
                antall = this.hjemmetjeneste.treff.length;
                if (antall) {
                    return ' (' + antall + ')';
                }
            }
            return '';
        },
    },
    watch: {
        id: async function (value) {
            this.id = value;
            await this.load();
        },
    },
    created: async function () {
        await this.load();
        this.tab = 0;
    },
    methods: {
        ...mapActions('api', ['request', 'formatVisit', 'formatPerson']),
        ...mapActions(['snackbar']),

        /**
         * load
         */
        load: async function () {
            if (this.id) {
                const response = await this.request({
                    method: 'get',
                    url: '/hjemmetjeneste/' + this.id,
                });

                if (response && typeof response.id != 'undefined') {
                    await this.update(response);
                }
            }
        },

        /**
         * update
         */
        update: async function (hjemmetjeneste) {
            this.hjemmetjeneste = await this.formatVisit(hjemmetjeneste);

            this.form.avsluttet = this.hjemmetjeneste.avsluttet;
            this.form.beskrivelse = this.hjemmetjeneste.beskrivelse;
        },

        /**
         * save
         */
        save: async function () {
            if (!this.$refs.form.validate()) {
                console.log('TODO: error message');
            } else {
                try {
                    const data = {
                        typeId: this.hjemmetjeneste.type.id,
                        brukerId: this.hjemmetjeneste.bruker.id,
                        frivilligId: this.hjemmetjeneste.frivillig.id,
                        beskrivelse: this.form.beskrivelse,
                        avsluttet: this.form.avsluttet ? this.form.avsluttet.format('DD.MM.YYYY') : null,
                    };

                    const response = await this.request({
                        method: 'post',
                        url: '/hjemmetjeneste/' + this.id,
                        data: data,
                    });

                    if (response && typeof response.id != 'undefined') {
                        await this.update(response);
                        this.snackbar('Lagret');
                    }
                } catch (e) {
                    console.log(e);
                }
            }
        },
    },
};
</script>
